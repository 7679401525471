import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { postRequest } from '../config/functions'
import APIS from '../config/apis'
import toast from 'react-hot-toast'

const Register = () => {
  const router = useNavigate()

  const [login, setLogin] = useState({
    name: "",
    email: "",
    password: ""
  })

  const loginHandler = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }

  async function userRegister() {
    try {
      const data = await postRequest(APIS.REGISTER);
      if (data?.status) {
        localStorage.setItem('access-token', data?.token);
        localStorage.setItem('name', data?.name);
        toast.success(data.message);
        router('/')
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  return (
    <>
      <div className='container-fluid position-absolute d-flex w-100 m-0 p-0 h-100'>
        <div className='container login-card'>
          <h3 className='text-center mb-4'>Register</h3>
          <input type='text' autoComplete='off' name='name' value={login.name} onChange={loginHandler} placeholder='Enter the name' className='form-control' />
          <input type='email' autoComplete='off' name='email' value={login.email} onChange={loginHandler} placeholder='Enter the email' className='form-control' />
          <input type='password' autoComplete='off' name='password' value={login.password} onChange={loginHandler} placeholder='Enter the password' className='form-control' />
          <button className='btn btn-success w-100 m-auto mb-3' onClick={userRegister}>Register</button>
          <p className='text-center'>Already have an account?</p>
          <Link to="/login"><button className='btn btn-outline-success w-100 m-auto'>Login</button></Link>
        </div>
      </div>
    </>
  )
}

export default Register