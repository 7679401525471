import React, { useEffect, useState } from 'react'
import { BoxArrowRight } from 'bootstrap-icons-react'
import { useNavigate } from 'react-router-dom'
import { getRequest, postRequest } from '../config/functions'
import APIS, { BASE_URL } from '../config/apis'
import { toast } from 'react-hot-toast'
import Sidebar from './Sidebar'
import Typewriter from 'typewriter-effect'
import Popup from './Popup'

const Row = ({ color, image, message, by }) => {
  return (
    <div className={'container w-100 p-0' + color}>
      <div className='container'>
        <div className='row'>
          <div className={'d-flex mb-3 ' + (by === 'bot' ? 'left' : 'right')}>
            {by === 'bot' ? (
              <img
                src={image}
                className='img-fluid p-0'
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              />
            ) : null}
            <p
              className={
                'light-text message ' + (by === 'bot' ? 'left' : 'right')
              }
            >
              {message}
              {/* <Typewriter
                options={{ delay: 10 }}
                onInit={typewriter => {
                  typewriter.typeString(message).start()
                }}
              /> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Home = () => {
  const router = useNavigate()
  function logout() {
    localStorage.clear()
    router('/login')
  }

  const [messages, setMessages] = useState([
    {
      by: 'bot',
      message:
        "Welcome to your custom conversational chatbot"
    }
  ])
  const [query, setQuery] = useState('')

  const [details, setDetails] = useState({
    name: '',
    plan_type: ""
  })
  const [count, setCount] = useState(0);

  function toaster(message, green) {
    if (green) {
      toast.success(message);
    } else {
      toast.error(message)
    }
  }

  const getProfile = async () => {
    try {
      const data = await getRequest(APIS.GET_PROFILE);
      if (data.status) {
        setDetails(data.data)
        setCount(data.data.message_count)
      } else {
        if (data.code === 401) {
          localStorage.clear();
          router('/login');
        }
        // toaster(data.message,false)
      }
    } catch (err) {
      toaster(err.message, false)
    }
  }

  // useEffect(() => {
  //   getProfile();
  // }, [])

  async function sendQuery() {
    if (query.trim() !== '') {
      setMessages([
        ...messages,
        {
          by: 'me',
          message: query
        }
      ])
      setLoader(true)
      getAnswer()
    }
  }
  const [loader, setLoader] = useState(false)

  const getAnswer = async () => {
    try {

      setQuery('')
      scrollDown()
      const data = await postRequest(APIS.QUERY, { message: query })
      setCount(data.updated_count)
      setLoader(false)
      if (data?.status) {
        setMessages([
          ...messages,
          { by: 'me', message: query },
          { by: 'bot', message: data.answer }
        ])
        scrollDown()
      } else {
        toast.error(data.message)
        if (data.code === 401) {
          localStorage.clear()
          router('/login')
        }
      }
    } catch (err) {
      setLoader(false)
      toast.error(err.message)
    }
  }

  const scrollDown = () => {
    setTimeout(() => {
      let div = document.getElementById('message-bar')
      div.scrollTop = div.scrollHeight
    })
  }

  const imageGenerator = name => {
    let link = undefined
    return link
      ? link !== ''
        ? link
        : 'https://ui-avatars.com/api/?name=' +
        name.replaceAll(' ', '+') +
        '&size=40&background=random&color=fff&rounded=true'
      : 'https://ui-avatars.com/api/?name=' +
      name.replaceAll(' ', '+') +
      '&size=40&background=random&color=fff&rounded=true'
  }

  return (
    <>
      <div className='container-fluid position-absolute light-bg d-flex flex-column w-100 m-0 p-0 h-100'>
        <div className='d-flex' style={{ height: '100vh' }}>
          <Sidebar count={count ?? 0} plan={details.plan_type ?? "BASIC"} />
          <div className='w-100 d-flex flex-column'>
            <div className='row p-0 m-0 pt-2 pb-2 dark-bg shadow'>
              <div className='col d-flex justify-content-between container'>
                <div className='d-flex align-items-center'>
                  <span
                    className='me-2'
                    id='burger'
                    onClick={() => {
                      document.getElementById('sidebar').style.display = 'block'
                    }}
                  >
                    ☰
                  </span>
                  <img
                    src={details.profile_pic !== "" ? details.profile_pic : imageGenerator(details.name)}
                    className='img-fluid p-0'
                    style={{ borderRadius: '50%' }}
                  />
                  <h6
                    className='d-flex align-items-center mb-0 pl-5 text-white'
                    style={{ marginLeft: '15px' }}
                  >
                    {details.name}
                  </h6>
                </div>
                <div className='logout'>
                  <button className='btn btn-transparent' onClick={logout}>
                    <BoxArrowRight icon='box-arrow-right' />
                  </button>
                </div>
              </div>
            </div>
            <div className='overflow-auto pt-3' id='message-bar'>
              {messages.map(item => (
                <Row
                  color={item.by === 'me' ? 'light-bg' : 'dark-bg'}
                  message={item.message}
                  by={item.by}
                  image={
                    item.by === 'me'
                      ? imageGenerator("Y")
                      : '/logo.webp'
                  }
                />
              ))}
            </div>
            <div className='container-fluid m-0 p-3 mt-auto dark-bg'>
              <div className='container'>
                <form>
                  <div className='d-flex'>
                    <input
                      type='text'
                      value={query}
                      className='form-control shadow'
                      onChange={e => {
                        setQuery(e.target.value)
                      }}
                    />
                    <button
                      disabled={loader}
                      type='submit'
                      onClick={sendQuery}
                      className='btn btn-primary bg-white border-0 send-btn'
                    >
                      <img
                        src={loader ? '/dots.gif' : '/send.png'}
                        className='img-fluid'
                      />
                    </button>
                  </div>
                </form>
                <p className='mb-0 bottom mt-2'>
                  <span style={{ cursor: "pointer", marginRight: "20px" }} data-toggle="tooltip" data-placement="top" title="Free Research Preview. IndianTaxGPT may produce inaccurate information about facts. We are improving daily. Help us to improve and share your feedback ">
                    Disclaimer
                  </span>
                  <small> <a href='mailto:reachus@dziretechnologies.com' style={{ color: "black", marginRight: "20px" }}> Mail us</a></small>
                  <span> <a href='tel:+919568334165' style={{ color: "white" }}>Contact Us for Most Affordable Tax Services</a></span>
                </p>
                <p className='mb-0 bottom mt-2'>
                  Made by{' '}
                  <a
                    href='https://dziretechnolgies.com'
                    style={{ color: 'black' }}
                  >
                    &nbsp;&nbsp;Dzire Technologies Pvt. Ltd.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  )
}

export default Home
