import React from 'react'
import { useNavigate } from 'react-router-dom';

const Sidebar = ({count,plan}) => {
    const router = useNavigate();
    function moveToPricingPage(){
        router('/pricing');
    }
    return (
        <div className='sidebar' id='sidebar'>
            <div className='bg-white' id='catcher' style={{
                alignItems: "center",
                height: "56px",
                display: "flex",
                paddingLeft: "15px"
            }}>
                <img src='/logo.webp' className='img-fluid logo mt-0' /><span className='logo-text'> Dzire Technologies</span><span style={{ marginRight: "20px" }} id="closerbtn" onClick={() => {
                    document.getElementById("sidebar").style.display = "none";
                }}>&#x2715;</span>
            </div>
            <div className='cover'>
                <div>
                <h5><img src="/chaticon.png" className='img-fluid' /><strong>IndianTaxGPT</strong></h5>
                    <ul>
                        <li>
                            Round-the-Clock Support: IndianTaxGPT offers 24/7 chatbot assistance, ensuring you can get your queries about Indian tax answered anytime, anywhere. 🕒💬
                        </li>
                        <li>
                            Expert Guidance: Our AI-powered chatbot provides accurate and reliable information on Indian tax regulations, helping you navigate through complex tax-related questions with ease. 📚🤖
                        </li>
                    </ul>
                </div>
                <div className='mt-auto'>
                <button className='w-100 btn upgrade-btn btn-primary mb-2'><i className='fa fa-comment me-1'></i> {count} Available</button>
                {plan === "BASIC" && <button className='w-100 btn btn-success upgrade-btn mb-1' 
                onClick={moveToPricingPage}
                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                ><i className='fa fa-magic me-3'></i>Upgrade to Premium</button>}
                {(plan === "BASIC" || plan === "PREMIUM") && <button className='w-100 btn btn-success upgrade-btn' 
                onClick={moveToPricingPage}
                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                ><i className='fa fa-magic me-3'></i>Upgrade to Enterprise</button>}
                </div>
                {/* <p>Made by <a href="https://dziretechnologies.com">Zoptal Solutions</a></p>
                            <p>Follow Us on Twitter <a href='https://twitter.com/dziretechnology'>@dziretechnologies</a></p> */}
            </div>
        </div>
    )
}

export default Sidebar