import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { getRequest } from '../config/functions';
import APIS from '../config/apis';

const Pricing = () => {
    const router = useNavigate();
    const [details, setDetails] = useState({
        name: '',
        message_count : 0,
        plan_type : ""
      })
    function toaster(message, green) {
        if (green) {
          toast.success(message);
        } else {
          toast.error(message)
        }
      }
    
      const getProfile = async () => {
        try{
          const data = await getRequest(APIS.GET_PROFILE);
          if(data.status){
            setDetails(data.data)
          }else{
            if(data.code === 401){
              localStorage.clear();
              router('/login');
            }
            toaster(data.message,false)
          }
        }catch(err){
          toaster(err.message,false)
        }
      }
    
      // useEffect(() => {
      //   getProfile();
      // }, []);

    return (
        <div className='container mb-5 mt-5'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='pricing-box'>
                        <h5>Free</h5>
                        <h1>₹0 <span className="month"> </span></h1>
                        <button className='btn btn-primary m-auto'>{details?.plan_type === "BASIC" && <i className="fa fa-check" style={{fontSize : "15px", color : "white", marginRight : "0px"}}></i>} {details?.plan_type === "BASIC" ? "Subscribed" : "Subscribe"}</button>
                        <hr/>
                        <ul>
                            <li><i className="fa fa-check"></i>Message Credits 50</li>
                            <li><i className="fa fa-times"></i>Chatbot Customization</li>
                            <li><i className="fa fa-times"></i>Dedicated customer support</li>
                            <li><i className="fa fa-times"></i>Live Tax consultancy</li>
                            <li><i className="fa fa-times"></i>ITR/GST Filling Support</li>
                            <li><i className="fa fa-times"></i>Voice to Text</li>
                            {/* <li><i className="fa fa-check"></i>1000 messages</li> */}
                        </ul>
                    </div>
                </div>
                <div className='col-md-4'>
                <div className='pricing-box'>
                        <h5>Premium</h5>
                        <h1>₹500 <span className="month"> Monthly </span></h1>
                        <button className='btn btn-primary m-auto'>{details?.plan_type === "PREMIUM" && <i className="fa fa-check" style={{fontSize : "15px", color : "white", marginRight : "0px"}}></i>} {details?.plan_type === "PREMIUM" ? "Subscribed" : "Subscribe"}</button>
                        <hr/>
                        <ul>
                            <li><i className="fa fa-check"></i>Message Credits 2000</li>
                            <li><i className="fa fa-times"></i>Chatbot Customization</li>
                            <li><i className="fa fa-check"></i>Dedicated customer support</li>
                            <li><i className="fa fa-check"></i>Live Tax consultancy</li>
                            <li><i className="fa fa-check"></i>ITR/GST Filling Support</li>
                            <li><i className="fa fa-times"></i>Voice to Text</li>
                            {/* <li><i className="fa fa-check"></i>1000 messages</li> */}
                        </ul>
                    </div>
                </div>
                <div className='col-md-4'>
                <div className='pricing-box'>
                        <h5>Enterprise</h5>
                        <h1>₹5000* <span className="month"> Monthly </span></h1>
                        <button className='btn btn-primary m-auto' onClick={()=>{
                            router('/contact')
                        }}>{details?.plan_type === "ENTERPRISES" && <i className="fa fa-check" style={{fontSize : "15px", color : "white", marginRight : "0px"}}></i>} {details?.plan_type === "ENTERPRISES" ? "Subscribed" : "Subscribe"}</button>
                        <hr/>
                        <ul>
                            <li><i className="fa fa-check"></i>Message Credits Unlimited</li>
                            <li><i className="fa fa-check"></i>Chatbot Customization </li>
                            <li><i className='fa fa-check' style={{color : 'white'}}></i><span style={{fontSize : "10px"}}>(Multipe PDFs upto 2,000,000 Chars)</span></li>
                            <li><i className="fa fa-check"></i>Dedicated customer support</li>
                            <li><i className="fa fa-check"></i>Live Tax consultancy</li>
                            <li><i className="fa fa-check"></i>ITR/GST Filling Support</li>
                            <li><i className="fa fa-check"></i>Voice to Text</li>
                            {/* <li><i className="fa fa-check"></i>1000 messages</li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing