import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login';
import Home from './Home';
import Register from './Register';
import Forgotpwd from './Forgotpwd';
import Auth from '../guards/Auth';
import Protected from '../guards/Protected';
import UploadFiles from './UploadFiles';
import Pricing from './Pricing';
import Index from './Index';
import Contact from './Contact';

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/pricing" element={<Pricing/>} />
      {/* <Route exact path="/" element={<Index/>} /> */}
      <Route exact path="/contact" element={<Contact/>} />
      <Route exact path="/login" element={
        <Auth>
          <Login />
        </Auth>} />
      <Route exact path="/register" element={
        <Auth>
          <Register />
        </Auth>} />
      <Route exact path="/forgot-password" element={
        <Auth>
          <Forgotpwd />
        </Auth>
      } />
      <Route exact path="/" element={
        <Protected>
          <Home />
        </Protected>
      } />
      {/* <Route exact path="/admin" element={
       <Protected>
          <UploadFiles />
        </Protected>
      } /> */}
    </Routes>
  )
}

export default Routing
