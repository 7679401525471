async function getRequest(route) {
    const token = localStorage.getItem('access-token')
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token ?? "");
    const response = await fetch(route, {
        method: "GET",
        headers: myHeaders
    });
    return response.json();
}

async function postRequest(route, data) {
    const token = localStorage.getItem('access-token')
    const myHeaders = new Headers();
    myHeaders.append("access-token", token ?? "");
    myHeaders.append("Content-Type","application/json")
    const response = await fetch(route, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders
    });
    return response.json();
}


export { getRequest, postRequest }