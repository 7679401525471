// const BASE_URL = "https://api.indiantaxgpt.com/"
const BASE_URL = "https://www.indiantaxgpt.com/"

const APIS = {
    LOGIN: BASE_URL + "login",
    REGISTER: BASE_URL + "register",
    FORGOT: BASE_URL + "forgot-password",
    QUERY: BASE_URL+"chat",
    UPLOAD_FILES: BASE_URL+"upload",
    GET_PROFILE: BASE_URL+"profile"
}

export default APIS;
export { BASE_URL }