import React from 'react'
import { useState } from 'react'

const Contact = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        contact: "",
        description: ""
    });
    function handleInput(e) {
        if (e.target.name === 'contact') {
            if (!isNaN(e.target.value)) {
                setForm({
                    ...form,
                    [e.target.name]: e.target.value
                });
            }
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            });
        }
    }
    const submitRequest = async () => {
        try {

        } catch (err) {

        }
    }
    function handleSubmit() {

    }
    const [message, setMessage] = useState("");
    const [status,setStatus] = useState(false);
    return (
        <div className='container pt-5'>
            <h3 className='text-center'>Contact Us</h3>
            <div className='card'>
                <div className='form-control'>
                    <input type="text" placeholder='Name' name='name' value={form.name} onChange={handleInput} />
                </div>
                <div className='form-control'>
                    <input type="email" placeholder='Email' name='email' value={form.email} onChange={handleInput} />
                </div>
                <div className='form-control'>
                    <input type="text" placeholder='Contact Number' maxLength={10} name='contact' value={form.contact} onChange={handleInput} />
                </div>
                <div className=''>
                    <textarea type="text" className='form-control' rows={6} name='description' onChange={handleInput} placeholder='Describe your product'>
                        {form.name}
                    </textarea>
                </div>
                {message !== "" ? <div class={"alert alert-"+status ? "success" : "danger"} role="alert">
                    {message}
                </div> : null}
                <button type='button' className='btn btn-primary contact-btn' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default Contact