import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { postRequest } from '../config/functions'
import APIS from '../config/apis'
import toast from 'react-hot-toast'
import { LoginSocialGoogle } from 'reactjs-social-login';
import axios from 'axios'
import Pricing from './Pricing'

const Login = () => {
  const router = useNavigate()
  // const [login, setLogin] = useState({
  //   email: "",
  //   password: ""
  // });

  function toaster(message, green) {
    if (green) {
      toast.success(message);
    } else {
      toast.error(message)
    }
  }

  const onLoginStart = useCallback(() => {

  }, []);

  const onLogoutSuccess = useCallback(() => {
    alert('logout success');
  }, []);

  const socialLogin = async (socialInfo) => {
    try {
      const response = await axios.post(APIS.LOGIN, socialInfo);
      if (response.data.status) {
        toaster(response.data.message, true)
        let uid = response.data.info._id;
        localStorage.setItem('access-token', response?.data?.info?.x_token ?? "");
        localStorage.setItem('name', response?.data?.info?.name ?? "");
        localStorage.setItem('name', response?.data?.info?.name ?? "");
        localStorage.setItem('profile_pic', response?.data?.info?.profile_pic ?? "");
        localStorage.setItem('_id', uid);
        router('/')
      } else {
        toaster(response.data.message, false);
      }
    } catch (err) {
      toaster(err.message, false)
    }
  }

  const onLogout = useCallback(() => { }, []);

  // const loginHandler = (e) => {
  //   setLogin({
  //     ...login,
  //     [e.target.name]: e.target.value
  //   })
  // }

  // async function userLogin() {
  //   try {
  //     localStorage.setItem('access-token', "dsbdkbskbdkssbkdsbkbdkjbkjsbkjdbkjsbksjbs0");
  //     localStorage.setItem('name', "Rajkumar Yadav");
  //     const data = await postRequest(APIS.LOGIN);
  //     if (data?.status) {
  //       localStorage.setItem('access-token', data?.token);
  //       localStorage.setItem('name', data?.name);
  //       toast.success(data.message);
  //       router('/')
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (err) {
  //     toast.error(err.message)
  //   }
  // }

  return (
    <>
      <div className='container-fluid position-absolute d-flex w-100 m-0 p-0 h-100'>
        <div className='container login-card'>
          <div className='text-center'><img src='/logo.webp' className='img-fluid' /></div>
          <h3 className='text-center mb-4 mt-4'><strong>💬 IndianTaxGPT</strong></h3>
          <h6 className='text-center'>Welcome to India's 1st Ever Tax Chatbot</h6>
          <div className='social'>
            <LoginSocialGoogle
              client_id={'301937989042-42es7r3dv9cq2gol5sjr7mdp0ckofist.apps.googleusercontent.com'}
              onLoginStart={onLoginStart}
              redirect_uri={"https://indiantaxgpt.com"}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) => {
                socialLogin({
                  x_token: data.access_token,
                  email: data.email,
                  name: data.name,
                  profile_pic: data.picture,
                  social_id: data.sub,
                  login_type: provider.trim().toUpperCase(),
                });
              }}
              onReject={err => {
                toast.error(err.message, false)
              }}
            >
              <div className="google_login social_login_btn">
                <button className='btn btn-success'>
                  <span>
                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" >
                      <path d="M11.5004 4.44715C13.1482 4.4218 14.7418 5.03568 15.9467 6.15999L19.1921 2.98643C17.1099 1.03534 14.3536 -0.0345242 11.5004 0.000850058C9.36853 0.000357454 7.27856 0.592919 5.46421 1.71227C3.64986 2.83162 2.18266 4.43362 1.22668 6.33912L4.94539 9.22628C5.40009 7.84369 6.27732 6.63871 7.45338 5.78127C8.62944 4.92383 10.045 4.45719 11.5004 4.44715Z" fill="#E43E2B" />
                      <path d="M22.5401 11.7571C22.5537 10.9666 22.472 10.1773 22.2969 9.40625H11.5001V13.6745H17.8384C17.7182 14.4228 17.4478 15.1391 17.0436 15.7803C16.6394 16.4214 16.1097 16.9742 15.4864 17.4054L19.1156 20.2162C20.2466 19.1243 21.136 17.8072 21.7265 16.3502C22.3169 14.8931 22.5952 13.3284 22.5434 11.7571H22.5401Z" fill="#3B7DED" />
                      <path d="M4.95865 13.7752C4.70721 13.0431 4.57759 12.2747 4.57495 11.5006C4.57953 10.7278 4.70451 9.96038 4.94538 9.22603L1.22668 6.33887C0.420122 7.94017 0 9.70819 0 11.5012C0 13.2941 0.420122 15.0621 1.22668 16.6634L4.95865 13.7752Z" fill="#F0B501" />
                      <path d="M11.5003 23.0008C14.3015 23.08 17.0267 22.0833 19.1157 20.2154L15.4866 17.4045C14.3099 18.1934 12.9164 18.5954 11.5003 18.5545C10.0461 18.5459 8.63162 18.0796 7.4574 17.2217C6.28319 16.3639 5.4089 15.1581 4.95857 13.7754L1.23987 16.6637C2.19326 18.5683 3.65806 20.1699 5.47023 21.2892C7.28239 22.4084 9.37036 23.0011 11.5003 23.0008Z" fill="#2BA24C" />
                    </svg>
                  </span><span>Continue with Google</span></button>
              </div>
            </LoginSocialGoogle>
            {/* <Pricing /> */}
          </div>
          <footer>
            <div className='fixed-bottom bg-white pt-2'>
              <div className='text-center'>
                &copy; Powered by <a href='https://dziretechnologies.com'>Dzire Technologies</a>
              </div>
            </div>
          </footer>
          {/* <input type='email' autoComplete='off' name='email' value={login.email} onChange={loginHandler} placeholder='Enter the email' className='form-control' />
          <input type='password' name='password' value={login.password} onChange={loginHandler} autoComplete='off' placeholder='Enter the password' className='form-control' />
          <button className='btn btn-success w-100 m-auto mb-3' onClick={userLogin}>Login</button> */}
          {/* <p className='text-center'>Don't have an account?</p> */}
          {/* <Link to="/register"><button className='btn btn-outline-success w-100 m-auto'>Register</button></Link> */}
          {/* <Link to="/forgot-password"><p className='text-center m-auto w-100 forgot-text mt-2 small'>Forgot Password</p></Link> */}
        </div>
      </div>
    </>
  )
}

export default Login