import React from 'react'
import { Navigate } from 'react-router-dom';

const Auth = ({ children }) => {
    if(localStorage.getItem('access-token')){
        return <Navigate to="/" replace />;
    }
    return children
}

export default Auth