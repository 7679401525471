import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { postRequest } from '../config/functions'
import APIS from '../config/apis'
import toast from 'react-hot-toast'

const Forgotpwd = () => {
  const router = useNavigate()
  const [login, setLogin] = useState({
    email: ""
  });

  const loginHandler = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }

  async function userLogin() {
    try {
      const data = await postRequest(APIS.LOGIN);
      if (data?.status) {
        toast.success(data.message);
        router('/')
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  return (
    <>
      <div className='container-fluid position-absolute d-flex w-100 m-0 p-0 h-100'>
        <div className='container login-card'>
          <h3 className='text-center mb-4'>Recover Account</h3>
          <input type='email' autoComplete='off' name='email' value={login.email} onChange={loginHandler} placeholder='Enter the email' className='form-control' />
          <button className='btn btn-success w-100 m-auto mb-3' onClick={userLogin}>Send Password</button>
          <p className='text-center'>Do you have credentials ?</p>
          <Link to="/login"><button className='btn btn-outline-success w-100 m-auto'>Login</button></Link>
        </div>
      </div>
    </>
  )
}

export default Forgotpwd